import { FC } from 'react'
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation, DocumentNode, useQuery } from '@apollo/client'
import { TablerIcon } from '@tabler/icons'
import { useFormik } from 'formik'
import { ModalBody, Input, InputSelect } from '..'
import { ErrorModal, ErrorType } from '../../utils/Error'
import { getStorageItem, hasPlatformFeature, hiphenValidator, removeStorageItem, getTriggers } from "../../utils"
import { FETCH_LISTS } from '../../graphql/query/list'
import moment from 'moment-timezone'

interface List {
  id: number
  name: string | undefined | null
  contactsCount: number | undefined | null
  locale: string | undefined | null
  doubleOptinMessage: string | undefined | null
  doubleOptin: boolean | undefined | null
  createdAt: Date | undefined | null
}
interface CreateNewCampaignBodyProps {
  label: string
  Icon: TablerIcon
  handleClose: () => void,
  mutation: DocumentNode,
  siteId: number
  type: string
  campaignType?: string
  isList?: boolean
  dripCampaignId?: number
  isDraft?: boolean
  history: any,
  dataAttributeCreateButton?: string
  dataAttributeCreateInput?: string
  dataAttributeCreateSelect?: string
  segmentIds?: Array<number>
}

interface selectOptionsInterface {
  label: string | null | undefined,  
  value: string | number 
}


export const CreateNewCampaignBody: FC<CreateNewCampaignBodyProps> = ({ 
  Icon, 
  handleClose, 
  mutation, 
  label, 
  siteId,
  type, 
  campaignType, 
  isList = true,
  dripCampaignId,
  isDraft = false,
  history,
  dataAttributeCreateButton = "",
  dataAttributeCreateInput = "",
  dataAttributeCreateSelect = "",
  segmentIds = []
}) => {
  const intl = useIntl()
  const isAutomation = type === 'automations'
  const isAutoresponder = type === 'autoresponders'

  const { data } = useQuery(FETCH_LISTS, { skip: !isList, variables: { siteId }})

  const lists = data?.lists?.map((list:List) => ({ label: list.name, value: list.id })) || []
  const triggers: Array<selectOptionsInterface> = getTriggers()

  const [mutationFunc, { loading: mutationLoading, client }] = useMutation(mutation)
  
  const selectOptions: Array<selectOptionsInterface> = isAutomation ? triggers : lists

  const { emailTemplateId, json } = JSON.parse(getStorageItem("customEmailTemplate")!) || {}

  const labelToNameMap: { [key: string]: string } = {
    "widgets": "Form",
    "landing_pages": "Landing Page",
    "broadcasts": "Broadcast",
    "automations": "Automation",
    "autoresponder": "Autoresponder",
    "drip_sequence": "Drip Sequence",
  };
  
  const getInitialCampaignName = (type: string): string => {
    const name = labelToNameMap[type] || label;
    const now = moment();
    return `New ${name} - ${now.format('D MMMM YYYY')}`
  };
 
  const campaignForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: getInitialCampaignName(type) || '',
      listId: isList && !isAutoresponder && !isAutomation ? selectOptions[0]?.value : undefined,
      ...(isAutoresponder && { listIds: []  }),
      trigger: isList && isAutomation ? selectOptions[0]?.value : undefined,
      siteId,
      type: campaignType,
      createEmail: (type ==='drips' && !dripCampaignId) ? true : undefined,
      dripCampaignId: dripCampaignId || undefined,
      ...(isAutomation && { eventName: ''}),
    },
    onSubmit: async (values) => {
      const listIds = values.listIds?.map(({value}) => value)
      const variables = {
        input: { 
          ...values, 
          listIds, 
          emailTemplateId: isDraft ? emailTemplateId : undefined, 
          json: isDraft ? json : undefined, 
          ...(segmentIds?.length && { segmentIds, target: 'segment' })
        }
      }
      const { data } = await mutationFunc({ variables })
      if(client?.cache){
        client.cache.evict({ id: "ROOT_QUERY", fieldName: "campaignFeed" });
        client.cache.gc();
      }
      const { createWidget, createLandingPage, createEmail, createAutomationJourney, createDripCampaign, createShopifyPage } = data
      const { widget, landingPage, email, journey, shopifyPage, dripCampaign, error } = createLandingPage || createEmail || createWidget || createAutomationJourney || createDripCampaign || createShopifyPage || {}
      if(error) {
        const alertConfig = ErrorType({ ...error, history, siteId })
        ErrorModal(alertConfig)
        handleClose()
      } else {
        if(isDraft && getStorageItem('customEmailTemplate')){
          removeStorageItem('customEmailTemplate')
          const { id } = email
          handleClose()
          return history.push(`/sites/${siteId}/${type}/${id}/edit/content`)
        }
        const { id } = widget || landingPage || email || journey || shopifyPage || dripCampaign?.dripEmails[0] || {}
        if (widget) {
          window?.track_mixpanel('widget-builder:create');
        } else if (landingPage) {
          window?.track_mixpanel('Landing Pages - New');
        } else if(journey) {
          window?.track_mixpanel('automation-journey:new-journey-created');
        }
        handleClose()
        return history.push(isAutomation ? `/sites/${siteId}/journeys/${id}` : `/sites/${siteId}/${type}/${id}/edit/template`)
      }
    },
    validate: (values) => {
      let errors: any = {};
      Object.keys(values).forEach((key) => {
        if(isAutoresponder && key === 'listIds'){
          if(!values[key]?.length)
          errors[key] = <FormattedMessage id="page.campaigns.errors.selectList" defaultMessage="Please select a list" />
        }
        if(isList && !isAutoresponder && !isAutomation && key === 'listId'){
          if(!values[key]){
            errors[key] = <FormattedMessage id="page.campaigns.errors.selectList" defaultMessage="Please select a list" />
          }
        }
        if(key === 'name') {
          if (!values[key] || !values[key].trim()) {
            errors[key] = <FormattedMessage id="validations.validations.required" defaultMessage="required" />;
          }
        }
        if(isAutomation && campaignForm?.values?.trigger === 'custom-event' && key === 'eventName') {
          if (!values[key]) {
            errors[key] = <FormattedMessage id="validations.validations.required" defaultMessage="required" />;
          }
        }
      });

      return errors;
    },
  });

  return (
    <ModalBody 
      icon={<Icon size="20px" />} 
      handleClose={handleClose}
      handleEnter={!mutationLoading ? campaignForm.submitForm : undefined}
      closeOnSubmit={false}
      title={
        <FormattedMessage values={{ type: label }} id='modal.campaigns.create.title' defaultMessage={`${label}`} />
      }
      action="primary" 
      closeIcon={false}
      buttons={[{ 
        reactIntlId: 'global.modal.confirm',
        label:"Confirm", 
        action:"primary", 
        onClick: campaignForm.submitForm, 
        disabled: mutationLoading,
        dataAttribute: dataAttributeCreateButton
      }]}
    >
      <div className="bg-gray-100 flex flex-col justify-center rounded w-full p-[24px]">
        <span className="text-sm text-gray-900 mb-[4px]">
          <FormattedMessage id='modal.campaigns.create.input.label' defaultMessage='Enter name for your campaign' />
        </span>
        <Input 
          id="name"
          autoFocus={true}
          action="primary"
          error={campaignForm.errors.name ? <FormattedMessage id='modal.campaigns.create.input.error' defaultMessage={campaignForm.errors.name} /> : ''}
          value={campaignForm.values.name.trimStart()} 
          onChange={campaignForm.handleChange}
          dataAttribute={dataAttributeCreateInput}
          />
        {
        isList && !(selectOptions.length === 1 && (typeof selectOptions[0].value === 'string') && ['custom-event', 'manual-event'].includes(selectOptions[0]?.value)) && <>
        <span className="text-sm text-gray-900 mb-[4px] mt-2">
          <FormattedMessage id='modal.campaigns.create.list.label' values={{ type: type === 'automations' ? 'trigger' : 'list' }} defaultMessage={`Select ${ type === 'automations' ? 'trigger' : 'list' }`} />
        </span>
        <InputSelect
          className="w-full" 
          searchable={isAutoresponder}
          placeholder={`Please select a ${type === 'automations' ? 'trigger' : 'list'}`}
          reactIntlPlaceholderId={type === 'automations'? 'modal.campaigns.create.selectTrigger':'modal.campaigns.create.selectList'} 
          {...(isAutomation && { defaultValue: selectOptions[0] })}
          error={isAutoresponder ? campaignForm.errors.listIds : campaignForm.errors.listId}
          value={isAutoresponder ? campaignForm?.values?.listIds?.map(({label, value}) => ({ label, value })) : !isAutomation ? selectOptions?.find((opt) => opt.value === campaignForm.values.listId) : selectOptions?.find((opt) => opt.value === campaignForm.values.trigger)}
          options={selectOptions} 
          onChange={opt => isAutoresponder ? campaignForm.setFieldValue("listIds", opt) : campaignForm.setFieldValue(isAutomation ? "trigger" : "listId", opt.value)} 
          isDisabled={isAutomation && selectOptions.length === 1}
          dataAttribute={dataAttributeCreateSelect}
          isMulti={isAutoresponder && true}
          />
          </>
          }
          {
          isAutomation && (campaignForm?.values?.trigger === 'custom-event') && <>
            <span className="text-sm text-gray-900 mb-[4px] mt-3">
              <FormattedMessage id='modal.campaigns.create.custom.event.name.label' defaultMessage='Enter name for your event' />
            </span>
            <Input 
              id="eventName"
              action="primary"
              error={
                campaignForm.errors.eventName ? <FormattedMessage id='modal.campaigns.create.custom.event.name.error' defaultMessage={campaignForm.errors.eventName} /> : ''
              }
              value={campaignForm.values.eventName?.trimStart()} 
              onChange={({ target: { value } }) => campaignForm.setFieldValue('eventName', hiphenValidator(value))}
              />
          </>
          }
      </div>
    </ModalBody>
  )
}