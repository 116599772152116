export function EmptyDataScreen({ name = "data", icon, heading, text, btnLoc = "below", children }) {
  return (
    <div className="flex flex-col items-center justify-center h-[400px] mt-28">
      <div className="bg-primary text-white rounded-full flex items-center justify-center p-4 mb-4">
        {icon}
      </div>
      <div className="text-center mb-4">
        <h3 className="text-xl">
          {heading || `No ${name} found`}
        </h3>
        <p className="font-light text-secondary text-[18px]">
          {text || `Looks like you haven't created any ${name} yet.`}
          <br/>
          {`Click the button ${btnLoc} to create one.`}
        </p>
      </div>
      {children}
    </div>
  );
}