import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { NavLink, useHistory } from "react-router-dom";
import { IconLifebuoy, IconCrown, IconMail, IconUser, IconLogout, IconWorld, IconNotebook, IconBrandHipchat, IconBrush, IconGhost, IconSettings, IconDashboard, IconFileText, IconAlertCircle, IconWand } from "@tabler/icons";
import { Button, Avatar, FloatingNotification, UtilityModal, ModalBody } from '..'
import AIBody from "../AIBody";
import { useQuery, useMutation } from '@apollo/client'
import moment from "moment-timezone";
import IconSparkle from "./IconSparkle";

import { NotificationTray } from "../NotificationTray";
import { Dropdown } from "../Dropdown";
import { floatingNotificationType } from "../NotificationTray/types";
import { FetchNotifications } from "../../graphql/query/notification";
import { MarkAsDone, MarkAsClose, MarkAsRead } from '../../graphql/mutation/notification'
import { client } from "../../graphql/client"
import { getDateDifference, getStorageItem, getTrialChecks, hasPlatformFeature, randomId, removeStorageItem, fetchFromStorage, removeFromStorage } from '../../utils'
import { TrailEndHeader } from './TrialEndHeader'
import { LogoImg } from "../"
import { RESEND_EMAIL } from "../../graphql/mutation/user";
import { ToastNotification } from "../Notifications/Toast";
import { ReactComponent as IconAddressBook } from '../../assets/svg/AddressBook.svg'

// Queries
import { DifyModal } from "../DIFY";
import { CURRENT_USER } from "../../graphql/query/me"
import { FETCH_SITE } from "../../graphql/query/site"
import { CreateNewInvite } from "../../modules/AppflowsOnboarding/Components/Pages/GetStarted";
import { LanguageSelectionDropdown } from "./components/LanguageSelectionDropdown";

const Header = ({ siteId, showSites = true }) => {
  const history = useHistory();
  const Navigation = [
    {
      label: "Campaigns",
      href: `/sites/${window.siteId}/campaigns`,
    },
    {
      label: "Contacts",
      href: `/sites/${window.siteId}/contacts`,
    },
    {
      label: "Analytics",
      href: `/sites/${window.siteId}/analytics`,
    },
  ]
  
  const { data = {}, loading } = useQuery(FetchNotifications, { skip: !fetchFromStorage('userToken') || !hasPlatformFeature("sites", "notifications") || !siteId, variables: { siteId }, pollInterval: 10000 });
  const { data: { site = {} } = {} } = useQuery(FETCH_SITE, { skip: !fetchFromStorage('userToken') || !siteId, variables: { id: siteId } });

  const { entitlements: { trialExpiresAt, siteOwnerCreatedAt: createdAt } = {}, businessProfile, isSiteOwner } = site || {}
  const { isCompleted: isSiteCompleted = false } = businessProfile || {}
  const { data: { me: user } = {} } = useQuery(CURRENT_USER, { skip: !fetchFromStorage('userToken') });
  const { entitlements: { pricingPlan } = {}, isVerified, email, isShopify, data: { shopify_trial_expires_at = null } = {} } = user || {};
  const isFreeUser = (pricingPlan === 'Free' || false);
  const [markAsDone] = useMutation(MarkAsDone);
  const [markAsRead] = useMutation(MarkAsRead);
  const [markAsClose] = useMutation(MarkAsClose);
  const isMobile = document.body.clientWidth < 600;
  const [resendEmail] = useMutation(RESEND_EMAIL)
  const lastDate = moment('2022-10-30').add(3, 'months').format('MMM DD, YYYY');
  const trialEndHeaderChecks = getTrialChecks(site) && siteId && !isMobile && hasPlatformFeature("account", "billing")
  const isShopifyTrialExpired = !!shopify_trial_expires_at && moment(shopify_trial_expires_at).isBefore(moment())

  const handleFloatingNotifications = () => {
    data && data.notifications && data.notifications.map((item) => {
      const { id, name, data, targets = [], readAt, redirectUrl } = item
      if(targets && targets.includes('floating') && !readAt) {

        const type = floatingNotificationType.filter(notificaiton => notificaiton.name == name)[0]
        if(type) {
          const { name, title, body, action, color, onClose = true, autoClose = true, onOpen, icon } = type
          FloatingNotification({
            name,
            id,
            top: trialEndHeaderChecks ? '108px' : '62px',
            icon,
            color,
            title: title({...data}),
            body: body({...data}),
            autoClose,
            onOpen: onOpen ? onOpen : () => id >= 0 && markAsRead({
              variables: { id: parseInt(id) },
            }),
            onClose: () => onClose && markAsClose({
              variables: {
                ...(id >= 0 ? { id: parseInt(id)} : { name }),
                siteId,
              }
            }),
            ...(action && { action: {
              label: action.label,
              customLabel: () => action.customLabel && action.customLabel({ ...data, redirectUrl }),
              onClick: () => {
                id >= 0 && markAsDone({
                  variables: { id: parseInt(id) }
                })
                action.onClick && action.onClick({ ...data, redirectUrl })
              }
            }})
          })
        }
      }
    })
  }

  useEffect(() => {
    Object.keys(data).length && hasPlatformFeature("sites", "notifications") && handleFloatingNotifications()
  },[data])


  const handleDropdownMenuClick = ({label, onClick}) => {
    if(onClick && typeof(onClick) === 'function') {
      onClick();
    }
  }

  const handleLogout = () => {
    fetch(`//${window.appDomain}/api/v1/auth/logout`, {
      method: "DELETE",
      body: JSON.stringify({
        token: fetchFromStorage('userToken')
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
    .then(response => response.json())
    .then((resp) => {
      const { success } = resp
      if(success) {
        removeFromStorage('userToken')
        removeFromStorage('agentToken')
        removeFromStorage('userImpersonated')
        removeFromStorage('userTokenExpiry')
        removeStorageItem('appLocale')
        client.clearStore().then(() => {
          window.location.href = `//${window.appDomain}/signout`
        }).catch(() => {
          window.location.href = `//${window.appDomain}/signout`
        });
      }
      //add mixpanel event for logout
    })
    .catch((error) => {
      console.log('ERROR while logging out')
    })
  }
  const daysLeft = getDateDifference(trialExpiresAt);
  const totalTrialDays = getDateDifference(trialExpiresAt, createdAt)

  const handleImpersonation = () => {
    removeFromStorage('userImpersonated')
    removeFromStorage('userToken')
    removeFromStorage('userTokenExpiry')
    window.location.href = `${process.env.REACT_APP_RAILS}/end_impersonate`
  }
  return (
    <>
    { trialEndHeaderChecks && <TrailEndHeader daysLeft={daysLeft} totalTrialDays={totalTrialDays} isSiteOwner={user?.id === site?.userId} /> }
    <div className="mx-auto relative z-[3]">
      <div
        className="px-2 sm:px-6 lg:px-8 relative flex justify-between h-16"
        style={{ boxShadow: "0px 4px 16px rgba(17, 17, 17, 0.04)" }}
      >
        <div className="flex-1 flex items-center justify-between sm:items-stretch sm:justify-start">
          <div className="flex-shrink-0 flex items-center">
            <LogoImg
              onClick={() => {
                if(isMobile) return;

                const home = window.isAppflows ? `/sites/${window.siteId}/campaigns` : '/'
                if(window.location.pathname.includes("billing")){
                  window.location.href = home
                }
                else {
                  history.push(home)
                }
              }}
            />
          </div>
          {!hasPlatformFeature('app_layout', 'side_navigation') &&
          <div className="flex-shrink-0 flex items-center ml-[120px]">
            {
              Navigation.map((item, index) => {
                return (
                  <NavLink
                    key={index}
                    className='cursor-pointer mr-[40px] font-light py-[18px]'
                    to={item.href}
                    activeStyle={{ color: 'var(--color-primary)', borderBottom: '2px solid var(--color-primary)' }}
                  >
                    {item.label}
                  </NavLink>
                )
              })
            }
          </div>}
        </div>
        <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
          <div className="flex items-center sm:mr-[24px]">
            {
              (
                hasPlatformFeature("account", "billing") &&
                ((isFreeUser && isSiteCompleted) || 
                (isFreeUser && history.location.pathname === '/sites'))
              ) &&
              <Button
                icon={<IconCrown stroke={2.5} className="mt-[-1px]" size={19} />}
                action='warning' 
                className='bg-warning-lighter text-warning hover:bg-warning hover:text-info'
                label='upgrade'
                reactIntlId="global.button.upgrade"
                onClick={() => {
                  window?.pricing?.show({
                    trial: isShopify && !isShopifyTrialExpired
                  });
                  window?.track_mixpanel('nav-bar:upgrade-button-click');
                }}
              />
            }
            {
              hasPlatformFeature('ai_assist', 'chatbot') && window.entitlements?.ai_writer &&
              <div>
                <IconSparkle className="cursor-pointer text-primary animate-pulse" onClick={() => {
                  UtilityModal({
                    width: '100%',
                    height: '100%',
                    style: {
                      backgroundColor: 'rgba(46, 56, 77, 0.4)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    },
                    className: 'flex items-center justify-center',
                    body: (props) => (
                      <AIBody {...props} siteId={siteId}/>
                    )
                  })
                }}/>
              </div>
            }
            { ['mailmunch', 'appflows'].includes((window.parameterizedPartnerName).toLowerCase()) && (typeof isVerified === 'boolean' && !isVerified) && <Button icon={<IconAlertCircle stroke={2.5} size={19} className="mt-[-1px]"/>} label='VERIFY ACCOUNT' className="bg-danger-lighter text-danger hover:bg-danger hover:text-white ml-[8px] whitespace-nowrap" onClick={()=>{
            window?.track_mixpanel('verify-account:topbar');
            UtilityModal({
              width: '425px',
              body: (props) => (
                <ModalBody
                  title={`Verify Account`}
                  action="danger"
                  icon={<IconAlertCircle />}
                  description={
                  <div>
                    Please verify your account by clicking the button below. It will take less than 30 seconds to complete. This is important to secure your account.
                  </div>
                  }
                  handleClose={props.handleClose}
                  closeOnEscape={true}
                  closeIcon={false}
                  buttons={[
                    {
                      label: "Verify",
                      action: "primary",
                      type: "default",
                      onClick: async() => {
                        window?.track_mixpanel('verify-account:topbar:modal:show');
                        try {
                          await resendEmail({
                            variables: {   
                              input: {
                                isEmailChanged: false
                              }, 
                            }
                          })
                          ToastNotification({
                            id: randomId(),
                            icon: 'check',
                            color: 'success',
                            body: `Verification email has been sent to ${email}`,
                            dataAttribute: 'email-verification-success'
                          })
                          history.push('/verify-email')
                        } catch (error) {
                          ToastNotification({
                            id: randomId(),
                            icon: 'alert',
                            color: 'danger',
                            body: `Error sending Verification email to ${email}`
                          })
                          history.push('/verify-email')
                        }
                      },
                    },
                  ]}
                />
              ),
            })
          }} /> }
            {
              hasPlatformFeature("customer_support") &&
              <Dropdown
                component={<IconLifebuoy className="text-secondary-dark mx-[10px] mt-[3px]" size="24px" />}
                width="220px"
                action="primary"
                alignLeft={true}
                callback={handleDropdownMenuClick}
                options={[
                  ...(
                    hasPlatformFeature("customer_support", "knowledge_base") ? [{
                      label: <FormattedMessage id='main.header.faq.knowledge_base' defaultMessage='View Knowledgebase' />,
                      value: "View Knowledgebase",
                      icon: (
                        <IconNotebook size={20} color={"#565E70"} stroke={1.5} />
                      ),
                      onClick: () => window.open(window.partnerLinks?.support, '_blank')
                    }] : []
                  ),
                  ...(
                    hasPlatformFeature("customer_support", "chat") && siteId ? [{
                      label: <FormattedMessage id='main.header.faq.chat' defaultMessage='Chat with us' />,
                      value: "Chat with us",
                      icon: (
                        <IconBrandHipchat size={20} color={"#565E70"} stroke={1.5}/>
                      ),
                      onClick: () => window?.Intercom('show')
                    }] : []
                  ),
                  ...(
                    hasPlatformFeature("customer_support", "dify") && !isMobile ? [{
                      label: <FormattedMessage id='main.header.faq.hire_expert' defaultMessage='Hire an expert' />,
                      value: "Hire an expert",
                      icon: (
                        <IconBrush size={20} color={"#565E70"} stroke={1.5} />
                      ),
                      onClick: () => {
                        window?.track_mixpanel('dify:support-dropdown:hire-button')
                        DifyModal({})
                      }
                    }] : []
                  ),
                  ...(
                    window.isAppflows ? [{
                      label: <FormattedMessage id='main.header.faq.send_docs' defaultMessage='Send Documentation' />,
                      value: "Send Documentation",
                      icon: (
                        <IconFileText size={20} color={"#565E70"} stroke={1.5} />
                      ),
                      onClick: () => {
                        UtilityModal({
                          body: (props) => <CreateNewInvite {...props} />,
                        })
                      }
                    }] : []
                  )
                ]}
              />
            }

          { hasPlatformFeature("sites", "notifications") && siteId && <div className="hidden sm:block"><NotificationTray siteId={siteId} data={data} loading={loading} /></div> }
          </div>
          {
            hasPlatformFeature("localization") && window.isBetaUser && <LanguageSelectionDropdown userId={user?.id} appLocale={user?.data?.appLocale} />
          }
          {
            user &&
            <Dropdown
              dataAttribute={"user-profile"}
              dropdownItemDataAttribute={"user"}
              component={
                <div className="h-[40px] flex items-center">
                  <Avatar />
                </div>
              }
              width="268px"
              display="hidden sm:flex"
              action="primary"
              alignLeft={true}
              callback={handleDropdownMenuClick}
              options={[
                {
                  // label: user?.email,
                  label: user?.displayEmail,
                  value: "email",
                  hasUnderline: true,
                  icon: <IconMail size={20} color={"#565E70"} stroke={1.5} />,
                  disabled: true,
                },
                ...(window.isAppflows ? [{
                  label: <FormattedMessage id='main.header.user_dropdown.settings' defaultMessage='Settings' />,
                  value: "site_settings",
                  icon: <IconSettings size={20} color={"#565E70"} stroke={1.5} />,
                  onClick: () => {
                    history.push(`/sites/${window.siteId}/settings`)
                  }
                }] : []),
                ...[
                  showSites && !window.isAppflows
                    ? {
                        label: <FormattedMessage id='main.header.user_dropdown.sites' defaultMessage='Sites' />,
                        value: "Sites",
                        icon: (
                          <IconWorld size={20} color={"#565E70"} stroke={1.5} />
                        ),
                        onClick: () => {
                          history.push("/sites");
                        },
                      }
                    : {},
                ],
                ...(
                  hasPlatformFeature('account') ? [{
                    label: <FormattedMessage id='main.header.user_dropdown.account' defaultMessage='Account' />,
                    value: "account",
                    hasUnderline: true,
                    icon: <IconUser size={20} color={"#565E70"} stroke={1.5} />,
                    onClick: () => {
                      if(window.location.pathname.includes("billing")){
                        window.location.href = "/account"
                      }
                      else{
                        history.push("/account")
                      }
                    },
                  }] : []
                ),
                ...(
                  window.parameterizedPartnerName === 'marketingmilk' ? [{
                    label: <FormattedMessage id='main.header.user_dropdown.dashboard' defaultMessage='Go to Dashboard' />,
                    value: "dashboard",
                    icon: <IconDashboard size={20} color={"#565E70"} stroke={1.5} />,
                    onClick: () => window.location.href = "https://dashboard.marketingmilk.com/",
                  },
                  {
                    label: <FormattedMessage id='main.header.user_dropdown.crm' defaultMessage='Go to CRM' />,
                    value: "crm",
                    hasUnderline: true,
                    icon: <IconAddressBook className="text-[#565E70] w-5 h-5" />,
                    onClick: () => window.location.href ="https://crm.marketingmilk.com/",
                  }] : []
                ),
                ...(
                  fetchFromStorage('userImpersonated') ?
                  [{
                    label: <FormattedMessage id='main.header.user_dropdown.impersonate' defaultMessage='Stop Impersonation' />,
                    value: "stopImpersonation",
                    icon: <IconGhost size={20} color={"#565E70"} stroke={1.5} />,
                    onClick: handleImpersonation,
                  }] : []
                ),
                {
                  label: <FormattedMessage id='main.header.user_dropdown.sign_out' defaultMessage='Sign Out' />,
                  value: "signout",
                  icon: <IconLogout size={20} color={"#565E70"} stroke={1.5} />,
                  onClick: () => handleLogout(),
                },
              ]}
            />
          }
        </div>
      </div>
    </div>
    </>
  );
};

export default Header;
