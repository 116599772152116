import { Fragment } from "react"
import { Checkbox } from ".."

const FilterBar = (props) => {
  const { filters = [], types = [], callback } = props

  const renderFilters = types.map((type, index) => {
    const { name, label, options } = type
    const isConsentEmailCampaign = window.location?.pathname?.includes('consents')
    if(isConsentEmailCampaign && filters.categories){
      filters.categories = {}
    }

    const renderOptions = options.map((opt,index) => {
      const isChecked = filters?.[name]?.[opt.name]
    
      const onClick = () => {
        if(isChecked) {
          let newValue = {...filters[name]}
          delete newValue[opt.name]
          callback({ name, value: newValue })
        }
        else {
          callback({ name, value: { ...filters[name], [opt.name]: true }})
        }
      }
      if(opt.isSection) {
        return <p className="text-[15px] font-normal text-gray-900 mb-[24px]">{opt.label}</p>
      }
      return <Checkbox 
              key={index} 
              label={opt.label} 
              className={`mb-[24px] ${isConsentEmailCampaign && 'opacity-50 pointer-events-none'}`} 
              checked={isConsentEmailCampaign ? false : isChecked} 
              onClick={onClick}
            />
    })

    return (
      <Fragment key={index}>
      <div key={index} className="w-[260px] pt-[8px]">
        { renderOptions }
      </div>
      {
        index < filters.length - 1 &&
        <div className="border-[1px] border-gray-400 my-[24px] w-[240px] relative ml-[-13px]"></div>
      }
      </Fragment>
    )
  })

  return (
    <div className="overflow-scroll">
    { renderFilters }
    </div>
  )
}

export { FilterBar }