import React from 'react'
import { Skeleton } from '../Skeleton'
import { Tooltip, TooltipProps } from '..'
import { FormattedMessage } from 'react-intl'
export interface TagProps {
  action: 'success' | 'danger' | 'warning' | 'primary' | 'default' | 'purple' | 'secondary'
  label?: string
  labelClassName?: string
  icon?: React.ReactNode
  iconPosition?: 'left' | 'right' | undefined
  dashed?: boolean
  className?: string
  disabled?: boolean
  count?: boolean
  loading?: boolean
  padding?: number | string
  onClick?: () => void
  tooltipProps?: TooltipProps
  dataAttribute?: string
  reactIntlId?: string
}

export const Tag: React.FC<TagProps> = ({ action, label, iconPosition, icon, dashed, disabled, className, padding, count, labelClassName, loading = false, onClick, tooltipProps, dataAttribute, reactIntlId }) => {

  const actions = {
    default: 'bg-gray-400 text-primary-light',
    primary: 'bg-primary-lighter text-primary',
    success: 'text-success bg-success-light',
    warning: 'bg-warning-light text-warning-dark',
    danger: 'bg-danger-light text-danger',
    purple: 'bg-purple-lighter text-purple',
    secondary: 'bg-gray-400 text-gray-800',
  }

  const onlyIcon = icon && !label && !iconPosition && 'w-[20px] rounded-sm'
  const iconAndLabel = icon && label && 'rounded-[100px]'
  const onlyLabel = label && !icon && !iconPosition && 'py-[4px] rounded-sm'
  const posStyle = (label && icon) && (iconPosition === "left" ? 'flex-row' :  iconPosition === "right" ? 'flex-row-reverse' : '')
  const disabledStyles = disabled ? "opacity-50" : 'cursor-pointer'
  const labelStyles = count ? "bg-warning text-white rounded-[8px] px-[6px] py-[1px]" : "";

  const TagBody = () => {
    return (
      <div 
        onClick={onClick} 
        className={`${disabled && disabledStyles} ${dashed && 'border border-dashed'} flex justify-center items-center h-[20px] text-center py-[4px] px-[8px] text-xs leading-3 ${actions[action]} ${posStyle} ${onlyIcon} ${iconAndLabel} ${onlyLabel} ${className}`}
        {...(dataAttribute && {'data-attribute': `tag-${dataAttribute}` })}
      >
        {
          icon &&
            <div className={`flex justify-center items-center ${label && iconPosition === "left" && 'mr-[8px]'}  ${(label && iconPosition === "right") && 'ml-[8px]'}`}>
              {icon}
            </div>
        }
        {label && <span translate="no" className={`${labelStyles} ${labelClassName}`} {...(dataAttribute && {'data-attribute': `tag-label-${dataAttribute}` })}>
          {
            reactIntlId ? <FormattedMessage id={reactIntlId} defaultMessage={label}/> : label 
          }
        </span>}
      </div>
    )
  }

  if(icon && !label) {
    return (
      <div onClick={onClick} style={{ padding }} className={`p-1 w-[20px] h-[20px] rounded-[2px] flex items-center justify-center ${actions[action]} ${className}`} {...(dataAttribute && {'data-attribute': `tag-${dataAttribute}` })}>
        {icon}
      </div>
    )
  }
  return ( 
    loading ? 
    <Skeleton width={65} height={20} /> 
    :
    tooltipProps ?
    <Tooltip {...tooltipProps}>
      <TagBody/>
    </Tooltip> 
    :
    <TagBody/>
  )
}

Tag.defaultProps = {
  action: 'default',
  iconPosition: undefined,
  className: ''
}