import { useState, useEffect, useRef } from "react"
import { IconX, IconCircleCheck } from "@tabler/icons"
import Proptypes from "prop-types"
import { render, unmountComponentAtNode } from 'react-dom'
import './index.css'
import { formatDataAttribute } from "../../../utils"

let readyQueue = []
let processedQueue = []

let Notifications = []
let callbackQueue = []
let prev = Date.now()
let div = null

let intervalId = null

// insert new notification every 300 ms
const initInterval = () => setInterval(() => {
  // delete notifications every 500 ms
  if((Date.now() - prev) > 1000) {
    if(callbackQueue.length > 0) {
      const cb = callbackQueue.splice(0,1)
      cb[0]()
    }
  }
  if(!document.hidden && div && readyQueue.length > 0) {
    const item = readyQueue.splice(0,1)
    processedQueue.push(item[0])
    Notifications.push(item[0])
    render(Notifications.map((item, index) => <Notification key={item.id} {...item} />), div)
  }
}, 500)

/* 
  action: {
    onClick,
    label
  }
*/

const Notification = (props) => {
  const {
    action,
    color,
    title,
    body,
    autoClose,
    actionCallback,
    onOpen,
    onClose,
    id = Date.now(),
    name
  } = props

  const { onClick } = action || {}

  const notificationContainer = useRef(null);
  const [isOpen,setIsOpen] = useState(true)

  useEffect(() => {
    onOpen && onOpen()
    if(autoClose && isOpen) {
      setTimeout(() =>
        callbackQueue.push(handleClose)
      ,10000)
    }
  },[])

  const handleClose = (e) => {
    e?.stopPropagation()
    onClose()
    // remove alert components
    if (isOpen && notificationContainer && notificationContainer.current) {
      // set time stamp to prevent another automatic handle close
      prev = Date.now()
      // add exit animation
      notificationContainer.current.style.animationName= 'fadeOutRight'
      notificationContainer.current.style.animationDuration = '0.5s'
      notificationContainer.current.style.animationFillMode = 'forwards'
  
      // remove current from global array
      const index = Notifications.findIndex((item) => item.id === id)
      Notifications.splice(index, 1)

      // move siblings up animation
      let nextSibling = notificationContainer.current.nextElementSibling

      while(nextSibling) {
        nextSibling.style.setProperty('--height',`-${notificationContainer.current.clientHeight}px`);
        nextSibling.style.animationName='slideInUp'
        nextSibling.style.animationDuration = '0.5s'
        nextSibling.style.animationFillMode = 'forwards'
        nextSibling = nextSibling.nextElementSibling
      }
      // remove after animation
      setTimeout(()=> {
        // reset animations so they can be applied again
        let nextSibling = notificationContainer.current?.nextElementSibling
        if(nextSibling){
          while(nextSibling) {
            nextSibling.style.animationName = 'none'
            nextSibling = nextSibling.nextElementSibling
          }
        }
        // hide current div so next sibling can take its position
        if(notificationContainer.current){
          notificationContainer.current.style.display = 'none'
        }
        setIsOpen(false)
        // unmount if no notifications left in array
        if(Notifications.length === 0) {
          const target = document.getElementById('mm-floating')
          if (target) {
            clearInterval(intervalId)
            intervalId = null
            unmountComponentAtNode(target)
            target.parentNode.removeChild(target)
          }
        }
      },500)
    }
  }

  const handleOnClick = () => {
    onClick && onClick()
    actionCallback()
    handleClose()
  }

  const variant = {
    color: {
      primary: "bg-primary",
      success: "bg-success",
      danger: "bg-danger"
    }
  }

  const colorStyles = variant.color[color]

  return (
    <>
    {
      isOpen &&
        <div ref={notificationContainer} onClick={handleOnClick} className="mm-floating-notification-anim cursor-pointer flex flex-col items-center space-y-4 sm:items-end w-[412px]">
          <div className="relative max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className={`py-[9px] pl-[13px] relative font-rubik text-secondary`}>
              <span className={`block ${colorStyles} absolute top-[38px] left-[20.88px] bottom-[12px] w-[4.68px] rounded-[4px]`}></span>
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <span className={`relative block ${colorStyles} h-[22px] w-[22px] rounded-[50%] flex justify-center items-center`}>
                   <props.icon size="16px" className="text-white" />
                  </span>
                </div>
                <div className="ml-[22px] text-secondary w-0 flex-1 pt-0.5">
                  <p className="text-[15px] leading-[18px]" data-attribute={`floating-notification-title-${formatDataAttribute(name)}`} >{title}</p>
                  <p className="mt-[4px] text-sm pb-[15px] opacity-80 leading-[17px]">{body}</p>
                </div>
                <div className="ml-[16px] flex-shrink-0 flex">
                  <button
                    className="absolute top-[10px] right-[18px] bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    data-attribute = "close-social-media-notification">
                    <span className="sr-only">Close</span>
                    <IconX stroke={1.25} color={"#2E384D"} size={16} data-attribute={`floating-notification-close-${formatDataAttribute(name)}`}
                      onClick={(e) => 
                        handleClose(e)
                      }
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
    }
    </>
  )
}

const createElements = (props) => {

  readyQueue.push(props)

  div = document.getElementById('mm-floating')
  if (!div) {
    div = document.createElement('div')
    div.id = 'mm-floating'
    div.style.top = props.top
    document.body.appendChild(div)
  }
}

export const FloatingNotification = (props) => {
  // only create if notification was not processed before
  const processedIndex = processedQueue.findIndex((item) => item.id === props.id)
  const readyQueueIndex = readyQueue.findIndex((item) => item.id === props.id)
  
  if(processedIndex == -1 && readyQueueIndex == -1) {
    
    if(!intervalId) {
      intervalId = initInterval()
    }
    createElements(props)
  }
}

Notification.propTypes = {
  actionCallback: Proptypes.func,
  autoClose: Proptypes.bool,
  body: Proptypes.string,
  color: Proptypes.oneOf(["primary", "success", "danger"]),
  customIcon: Proptypes.element,
  icon: Proptypes.element,
  id: Proptypes.number,
  onClose: Proptypes.func,
  onOpen: Proptypes.func,
  title: Proptypes.string,
};

Notification.defaultProps = {
  actionCallback: () => console.log(""),
  autoClose: true,
  body: 'Something went wrong',
  color: "success",
  customIcon: <IconCircleCheck size={18} stroke={1.25} color={"#FFFFFF"} />,
  icon: "bell",
  id: Date.now(),
  onClose: () => console.log("MM Floating OnClose"),
  onOpen: () => console.log("MM Floating OnOpen"),
  title: "Warning"
}