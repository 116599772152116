import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Popup } from "../index.ts"
import { IconCalendar } from '@tabler/icons';

const DateLabel = ({ value }) => {
  const getDate = (date) => {
    if (!date) return '';
    const dateObj = new Date(date);
    return `${dateObj.getDate().toString().padStart(2, '0')}-${(dateObj.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${dateObj.getFullYear()}`;
  };

  const className = !(value?.dateRange?.gte && value?.dateRange?.lte) && 'text-gray-800';

  return (
    <div className={`text-[14px] font-normal flex justify-between items-center w-full ${className}`}>
      <div>
        {getDate(value?.dateRange?.gte) || 'From Date'} <b>-</b> {getDate(value?.dateRange?.lte) || 'To Date'}
      </div>
      <div>
        {/* <IconCalendar color='#586071' size={20} /> */}
      </div>
    </div>
  );
};

export const DateRange = ({ value, onChange, className = '' }) => {
  const handleChange = (dates) => {
    const [start, end] = dates;

    onChange({
      ...value,
      dateRange: {
        ...value.dateRange,
        gte: start,
        lte: end,
      },
    });
  };

  return (
    <Popup
      alignRight={true}
      className={`p-1 bp-0`}
      component={() => (
        <Button
          label={<DateLabel value={value} />}
          action='outline'
          className={`px-2 bg-transparent border border-gray-800 min-w-[200px] normal-case justify-between ${className}`}
        />
      )}
      content={({ close }) => (
        <DatePicker
          selected={null}
          swapRange
          startDate={value.dateRange.gte ? new Date(value.dateRange.gte) : null}
          endDate={value.dateRange.lte ? new Date(value.dateRange.lte) : null}
          onChange={handleChange}
          selectsRange
          selectsDisabledDaysInRange
          inline
          dateFormat='MM-dd-yyyy'
          maxDate={new Date()}
        />
      )}
    />
  );
};
