import { ToastNotification } from '../components';
import { useState, useEffect, useRef } from 'react'
import update from 'react-addons-update'
import moment from 'moment-timezone'
import { IconBuildingWarehouse, IconCrown } from '@tabler/icons'
import { UtilityModal, ModalBody } from '../components'
import PasswordPageSvg from '../modules/Campaigns/assets/svg/PasswordPage';
import CollectionPageSvg from '../modules/Campaigns/assets/svg/CollectionPage';
import ProductPageSvg from '../modules/Campaigns/assets/svg/ProductPage';
import HomePageSvg from '../modules/Campaigns/assets/svg/HomePage';
import { IS_DOMAIN_SUPPRESSED } from '../graphql/query/domainSuppression';
import React from 'react';
import { FormattedMessage, } from 'react-intl';
import client from '../graphql/client';


export const flattenMessages = (nestedMessages, prefix = '') => {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    let value = nestedMessages[key]
    let prefixedKey = prefix ? `${prefix}.${key}` : key

    if (typeof value === 'string') {
      messages[prefixedKey] = value
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey))
    }

    return messages
  }, {})
}

export const getViewInBrowserLink = ({ shortCode = null }) => {
  const url = process.env.NODE_ENV === 'production' ? `https://${window?.domains?.pages_domain}/${shortCode}` : 'https://page.co/h7ntln';
  return (`
    <tr id='viewInBrowserLink'><td style="text-align: center; padding-top: 8px" height="30">
      <a
        style="color:#b1b1b1; text-align: center; font-family: helvetica; font-size: 12px; text-transform: uppercase; text-decoration: none" 
        href="${url}" 
        target="_blank">
          View in browser
      </a>
    </td></tr>
  `);
};

export const modifyHtmlForViewForInBrowser = ({ email, html }) => {
  const emailData = typeof(email?.data) === 'string' ? JSON.parse(email?.data || '{}') : (email?.data || {})
  const isViewInBrowserPresent = typeof(emailData?.viewInBrowser) === 'boolean';

  const viewInBrowserLink = document.querySelector('#viewInBrowserLink');
  const insertLink = ((!isViewInBrowserPresent) || (emailData?.viewInBrowser && !viewInBrowserLink));
  let doc;

  if (insertLink) {
    let parser = new DOMParser();
    doc = parser.parseFromString(html, "text/html");
    let tBody = doc.querySelector("tbody");
    const link = getViewInBrowserLink({ shortCode: email?.shortCode });
    
    tBody.innerHTML = link + tBody.innerHTML;
  }

  return { emailData, body: doc?.documentElement?.outerHTML || html, viewInBrowser: insertLink || false };
}

export const exportHtmlFromUnlayer = (params) => {
  return fetch('https://api.unlayer.com/v2/export/html', {
    method: 'POST',
    headers: {
      Authorization: `Basic ${process.env.REACT_APP_UNLAYER_API_KEY}`,
      accept: 'application/json', 
      'content-type': 'application/json',
    },
    body: JSON.stringify(params)
  });
};

export const getPlatform = ({ channelName }) => {
  if (channelName === 'google') return channelName;
  if (window?.isShopify) return 'shopify';
  if (window?.isWix) return 'wix';
  return 'app';
};

export const insertEmoji = ({ value, elementId = '', emoji }) => {
  const val = value || '';
  const index = document.getElementById(elementId)?.selectionStart;
  return val.substring(0, index) + emoji + val.substr(index);
};

export const emailTypeMap = {
  'Email::WelcomeEmail': 'autoresponders',
  'Email::JourneyEmail': 'automations',
  'Email::CartAbandonmentEmail': 'automations',
  'Email::BroadcastEmail': 'broadcasts',
  'Email::DripEmail': 'drips'
}

export const getFilteredTemplates = ({ options, appliedTemplateId }) => {
  const nonBlankTemplates = options.filter(({ tags }) => !(tags && tags.length && tags.includes('blank_template')))
  
  if ((!appliedTemplateId) || (!(nonBlankTemplates && nonBlankTemplates.length))) return nonBlankTemplates;

  let appliedTemplate = {};
  const filteredOptions = [];
  const siteIdTemplates = [];

  nonBlankTemplates.forEach((item) => {
    if (item.id === appliedTemplateId) {
      appliedTemplate = item;
    } else if (item.siteId) {
      siteIdTemplates.push(item);
    } else {
      filteredOptions.push(item);
    }
  });

  const sortedTemplates = appliedTemplate && appliedTemplate.id 
    ? [...siteIdTemplates, appliedTemplate,  ...filteredOptions] 
    : [...siteIdTemplates, ...filteredOptions];

  return sortedTemplates;
};

export const showToastNotification = ({ body, icon, color, holdTime = 4000, zIndex=999 }) => {
  ToastNotification({
    id: randomId(),
    icon,
    color,
    body,
    holdTime,
    zIndex
  });
};

export const isLeadQualityPersistent = (oldLeadQuality, newLeadQuality) => {
  const oldValues = Object.values(oldLeadQuality);
  const newValues = Object.values(newLeadQuality);

  if (!oldValues.length) return true;
  return ((JSON.stringify(oldValues) === JSON.stringify(newValues)) || (false));
};

export const replaceCoupons = (body, ecoupons) => {
  let newBody = body
  if(ecoupons?.length > 0){
    const coupons = body?.match(/{{coupon_\d+}}w*/g)
    if(coupons?.length > 0){
      for(let coupon of coupons){
        const [coupon_id] = coupon.match(/\d+/)
        const ECoupon = ecoupons?.find(ec => ec.id == coupon_id)
        const couponName = ECoupon ? (ECoupon.code || `{{${ECoupon.metaData.name}}}`) : ""
        newBody = newBody.replaceAll(coupon, couponName)
      }
    }
  }
  return newBody
}

export const isUserInTrialCohort = (site) => {
  const { entitlements: { trialExpiresAt } = {} } = site || {}
  return !!trialExpiresAt
}

export const getTrialChecks = (site) => {
  const { entitlements: { vanillaId } = {} } = site || {}
  return isUserInTrialCohort(site) && (vanillaId?.includes('trial') || vanillaId?.includes('free'))
}

export const trialEndModalForMembers = () => {
  return UtilityModal({
    id:'trial-modal-member',
    zIndex: 10000,
    body: param => 
      <ModalBody
        action='warning'
        title='Contact Administrator'
        cancelButton={false}
        description="Please contact the site administrator if you want to upgrade this site."
        handleClose={param.handleClose}
        icon={<IconCrown />}
        buttons={[{ action:"warning", label:"OK", onClick: param.handleClose }]}
      />
  })
}

const validateRules = async ({ allValues, errors: defaultErrors, previous, prevValues, values, validationRules, intl }) => {
  let errors = defaultErrors || {}
  const keys = Object.keys(validationRules)
  

  for(const key of keys) {
    const [ newKey, subKey ] = key.split('.')
    const rule = validationRules[key]
    const value = subKey ? values[newKey][subKey] : values[key]
    const prevValue = prevValues && subKey ? prevValues[newKey][subKey] : prevValues[key]
    const isRequired = rule.required
    const isNumeric = rule.numeric
    const asyncCallback = rule.asyncCallback
    const callback = rule.callback

    let isError = false
    // if key value has changed validate the key
    //if(!previous || !deepEqual(prevValue,value)) {
      if (isRequired && (Array.isArray(value) ? value.length == 0 : !value)) {
        isError = true
        
        errors = update(errors, { [key]: { $set: { state: true, errorMessage:  intl.formatMessage(
          { id: 'global.validations.is_required' ,
            defaultMessage: `is required`
          },
        )  }} })
      }
      if(isNumeric) {
        if(!isNaN(value) && value !== '') {
          const number = Number(value)
          if(number < rule.min || number > rule.max) {
            isError = true
            errors = update(errors, { [key]: { $set: { state: true, errorMessage:intl.formatMessage(
              { id: 'global.validations.must_be_between',
                defaultMessage: `${key} must be between ${rule.min} and ${rule.max}`
               },
              { field: key, min: rule.min, max: rule.max }
            )  }} })
          }
        }
        else {
          isError = true
          errors = update(errors, { [key]: { $set: { state: true, errorMessage: intl.formatMessage(
            { id: 'global.validations.must_be_number',
              defaultMessage: `${key} must be a number`
            },
            { field: key }
          ) }} })
        }
      }
      if (!isError && asyncCallback && !deepEqual(prevValue,value)) {
        const response = await asyncCallback()
        const { success, data, errorMessage } = response
        isError = success === false ? true : isError
        errors = update(errors, { [key]: { $set: { state: !success, data, errorMessage }} })
      }
      if (!isError && callback) {
        const { success, errorMessage } = callback()
        isError = success === false ? true : isError
        errors = update(errors, { [key]: { $set: { state: !success, errorMessage }} })
      }
      if(!isError)
        errors = update(errors, { [key]: { $set: { state: false }} })
   // }
  }

  // remove keys that have errors to prevent them from updating
  Object.keys(errors).forEach(key => {
    const [ mainKey, secondaryKey ] = key.split('.')
    if(!secondaryKey && errors[key] && errors[key]?.state) {
      delete allValues[key]
    }else if(secondaryKey && errors[key] && errors[key]?.state){
      delete allValues[mainKey][secondaryKey]
    }
  })

  // only update values which have changed
  Object.keys(allValues).forEach(key => {
    if(allValues[key] === prevValues[key]) {
      delete allValues[key]
    }
  })

  return [errors, allValues]
}

const deepEqual = (object1, object2) => {
  return JSON.stringify(object1) === JSON.stringify(object2)
  // function isObject(object) {
  //   return object != null && typeof object === 'object';
  // }

  // if(isObject(object1) && isObject(object2)) {
  //   const keys1 = Object.keys(object1);
  //   const keys2 = Object.keys(object2);

  //   if (keys1.length !== keys2.length) {
  //     return false;
  //   }

  //   for (const key of keys1) {
  //     const val1 = object1[key];
  //     const val2 = object2[key];
  //     const areObjects = isObject(val1) && isObject(val2);
  //     if (
  //       areObjects && !deepEqual(val1, val2) ||
  //       !areObjects && val1 !== val2
  //     ) {
  //       return false;
  //     }
  //   }
  //   return true
  // }
  // return false
}

export const validateEmail = email => {
  return !(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email));
};

export const validateDomain = domain => {
  let domainRegex = new RegExp(`^(ftp|http|https):\/\/[^ "]+$`);
  return domainRegex.test(domain);
};

export const validatePhone = phone => {
  return /^\d+$/.test(phone);
};

export const validateField = ({
  fieldValue,
  validationRules
}) => {
  let errMessage = '';
  validationRules.forEach((validation) => {
    const [validationRule, error] = Object.entries(validation);
    
    const [rule, ruleValue] = validationRule;
    const [,errorMessage] = error; 
    
    if (rule === 'min' && Number(fieldValue) < ruleValue) {
      errMessage = errorMessage;
    } else if (rule === 'max' && Number(fieldValue) > ruleValue) {
      errMessage = errorMessage;
    } else if (rule === 'required' && ((typeof(fieldValue) === 'string') && (!fieldValue.trim()))) {
      errMessage = errorMessage;
    } else if (rule === 'containsUnavailableCharacters' && (fieldValue.includes('_') || fieldValue.includes('|'))) {
      errMessage = errorMessage;
    }
  });
  return errMessage;
};

const resolveUpdate = ({ obj, path, value, action = '$merge' }) => {
  // create path if it does not exist
  if(!resolve(path,obj))
    return createPath({ path, value, values: obj })
  // update path if exists
  const keys = path?.split('.')
  const ret = keys.reduceRight((prev, curr) => {
    const newObj = update({}, { [curr]: { $set: prev }})
    return newObj
  },{ [action]: value })
  return ret
}

const createPath = ({ path, value, values }) => {
  let obj = {}
  let currVal = values
  let currObj = obj
  let notFound = false

  const keys = path?.split('.')
  keys.forEach((element,index) => {
    if(!(currVal && currVal[element]) && notFound === false) {
      currObj[element] = { $set: index === keys.length - 1 ? value : {}}
      currObj = currObj[element]['$set']
      notFound = true
    }
    else {
      currObj[element] = index === keys.length - 1 ? value : {}
      currObj = currObj[element]
    }
    currVal = currVal[element] ? currVal[element] : {}
  })
  return obj
}

const resolve = (path, obj) => {
  return path?.split('.').reduce((prev, curr) => {
      return prev ? prev[curr] : null
  }, obj)
}

const getFormFields = (json) => {
  const form = getObject(json, 'type', 'mailmunch#form')
  const { values = {}} = form || {}
  const { fields = []} = values
  return fields
}

const getObject = (theObject, key, value) => {
  var result = null;
  if(theObject instanceof Array) {
    for(var i = 0; i < theObject.length; i++) {
      result = getObject(theObject[i],key,value);
      if (result) {
          break;
      }   
    }
  }
  else
  {
    for(var prop in theObject) {
      if(prop === key) {
        if(theObject[prop] === value) {
          return theObject;
        }
      }
      if(theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
        result = getObject(theObject[prop],key,value);
        if (result) {
          break;
        }
      } 
    }
  }
  return result;
}

const hasPlatformFeature = (key, subKey) => {
  const { platformFeatures } = window || {}
  if(subKey) {
    if(platformFeatures?.[key]?.enabled && platformFeatures?.[key]?.features?.[subKey]?.enabled) {
      return true
    }
  } else if(platformFeatures?.[key]?.enabled) {
    return true
  }
  return false
}

/** Generates font sizes for unlayer text editor tool*/
const generateFontSizes = () => {
  const fontSizes = [];

  for(var i = 8; i <= 100; i > 46 ? i = i+4 : i = i +2 ) {
    fontSizes.push(`${i}px`);
  }   

  return fontSizes;
}

const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = value !== 'undefined' && !deepEqual(debouncedValue,value) && setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

const copyStringToClipboard = (str) => {
  // Create new element
  var el = document.createElement('textarea');
  // Set value (string to be copied)
  el.value = str;
  // Set non-editable to avoid focus and move outside of view
  el.setAttribute('readonly', '');
  el.style = {position: 'absolute', left: '-9999px'};
  document.body.appendChild(el);
  // Select text inside element
  el.select();
  // Copy text to clipboard
  document.execCommand('copy');
  // Remove temporary element
  document.body.removeChild(el);
}


const SHOPIFY_PAGE_TYPES = [
	{ 
    icon: ProductPageSvg,
    page_type: "shopify_pages",
    field: 'Shopify::ProductPage', 
    label: 'Product Page', 
    value: 'shopify_product_page', 
    tag: 'product_page', 
    entitlement: 'shopify_product_pages', 
    mixpanelEvent: 'product',
    textForDeleting: 'This layout will be deleted and all assigned products will be reverted to default layout.',
    textForUnassigning: 'This layout will be unpublished and all assigned products will be reverted to default layout.'
  },
	{ 
    icon: CollectionPageSvg,
    page_type: "shopify_pages",
    field: 'Shopify::CollectionPage', 
    label: 'Collection Page', 
    value: 'shopify_collection_page', 
    tag: 'collection_page', 
    entitlement: 'shopify_collection_pages', 
    mixpanelEvent: 'collection',
    textForDeleting: 'This layout will be deleted and all assigned collections will be reverted to default layout.',
    textForUnassigning: 'This layout will be unpublished and all assigned collections will be reverted to default layout.'
  },
	{ 
    icon: HomePageSvg,
    page_type: "shopify_pages",
    field: 'Shopify::HomePage', 
    label: 'Home Page', 
    value: 'shopify_home_page', 
    tag: 'home_page', 
    entitlement: 'shopify_home_pages', 
    mixpanelEvent: 'home',
    textForDeleting: 'This layout will be deleted and your store will revert to theme home page.',
    textForUnassigning: 'This layout will be unpublished and your store will revert to theme home page.'
  },
	{ 
    icon: PasswordPageSvg,
    page_type: "shopify_pages",
    field: 'Shopify::PasswordPage', 
    label: 'Password Page', 
    value: 'shopify_password_page', 
    tag: 'password_page', 
    entitlement: 'shopify_password_pages', 
    mixpanelEvent: 'password',
    textForDeleting: 'This layout will be deleted and your store will revert to theme password page.',
    textForUnassigning: 'This layout will be unpublished and your store will revert to theme password page.'
  },
	{ 
    icon: IconBuildingWarehouse,
    page_type: "shopify_pages",
    field: 'Shopify::Page', 
    label: 'Shopify Page', 
    value: 'shopify_page', 
    tag: 'shopify_page', 
    entitlement: 'shopify_pages', 
    mixpanelEvent: 'shopify'
  }
];

export const shopifyPages = {
  PRODUCT_PAGE: 'Shopify::ProductPage',
  COLLECTION_PAGE: 'Shopify::CollectionPage',
  HOME_PAGE: 'Shopify::HomePage',
  PASSWORD_PAGE: 'Shopify::PasswordPage',
  PAGE: 'Shopify::Page',
}

export const getNumberOfDays = ({ startDate, endDate = new Date().toISOString() }) => {
  const start = moment(startDate);
  const end = moment(endDate);
  return end.diff(start, 'days');
};

const getTime = (datetime, intl) => {
  let time = moment.parseZone(datetime);
  let now = moment();
  let diff = now.diff(time);

  if (diff < 60000) { // less than a minute
    return intl.formatMessage({ id: 'utils.time.now', defaultMessage: 'just now' });
  } else if (diff < 3600000) { // less than an hour
    let minutes = Math.floor(diff / 60000);
    return intl.formatMessage({ id: 'utils.time.minute_ago', defaultMessage: '{count, plural, one {# minute ago} other {# minutes ago}}' }, { count: minutes });
  } else if (diff < 86400000) { // less than a day
    let hours = Math.floor(diff / 3600000);
    return intl.formatMessage({ id: 'utils.time.hour_ago', defaultMessage: '{count, plural, one {# hour ago} other {# hours ago}}' }, { count: hours });
  } else if (diff < 604800000) { // less than a week
    let days = Math.floor(diff / 86400000);
    return intl.formatMessage({ id: 'utils.time.day_ago', defaultMessage: '{count, plural, one {# day ago} other {# days ago}}' }, { count: days });
  } else {
    return `${time.format("ll")} ${intl.formatMessage({ id: 'utils.time.at', defaultMessage: 'at' })} ${time.format("LT")}`;
  }
}

const getSortText = ({ sort, datetime }, intl) => {
  const sortMap = {
    'UPDATED_AT': intl.formatMessage({ id: 'utils.sort.updated', defaultMessage: 'Updated' }),
    'CREATED_AT': intl.formatMessage({ id: 'utils.sort.created', defaultMessage: 'Created' }),
    'SENT_AT': intl.formatMessage({ id: 'utils.sort.sent', defaultMessage: 'Sent' }),
  }
  
  let time = moment.parseZone(datetime);
  let preposition = moment().diff(time, 'days') >= 7 ? intl.formatMessage({ id: 'utils.time.on', defaultMessage: 'on' }) : '';
  
  return `${sortMap[sort]}${preposition ? ' ' + preposition : ''} `;
}


const getCampaignDate = (props) => {
  const { state, createdAt, queuedAt, sort = 'UPDATED_AT', updatedAt, campaignUpdatedAt,intl } = props;
  if (!!queuedAt && (state === 'completed' || state === 'queued')) {
    return <div>{getSortText({ sort: 'SENT_AT', datetime: queuedAt }, intl)} {getTime(queuedAt, intl)}</div>;
  } else {
    const datetime = sort === 'UPDATED_AT' ? campaignUpdatedAt || updatedAt : createdAt;
    return <div>{getSortText({ sort, datetime }, intl)} {getTime(datetime, intl)}</div>;
  }
}

const isValidURL = url => {
  const expression = /https?:\/\/(www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?/;
  const urlPattern = new RegExp(expression)
  return urlPattern.test(url);
}

export const formatDateDays = ({ date, mode, numberOfDays = 0 }) => {
  if (date) {
    const formattedDate = date;
    const updatedDate = mode === 'add' ? formattedDate.getDate() + numberOfDays : formattedDate.getDate() - numberOfDays
    formattedDate.setDate(updatedDate);
    return formattedDate;
  }
  return 'DATE IS REQUIRED';
};

const randomId = () => (Math.random() + 1).toString(36).substring(7)

const kFormatter = (num) => {
  if (!num) return;
  if (num < 1e3) return num;
  if (num >= 1e3 && num < 1e6) return (num / 1e3).toFixed(1) + "K";
  if (num >= 1e6 && num < 1e9) return (num / 1e6).toFixed(1) + "M";
  if (num >= 1e9 && num < 1e12) return (num / 1e9).toFixed(1) + "B";
}

const getStartDate = (daysAgo) => {
  let date = new Date();
  date.setDate(date.getDate() - daysAgo)
  return date.toISOString().slice(0, 10)
}

const getShortDate = (dateString) => {
  var options = {  year: 'numeric', month: 'short', day: 'numeric' };
  return new Date(dateString).toLocaleTimeString('en-us', options).slice(0, 12)
}

const getSiteStatus = (date) => {
  if (!date) {
    return false;
  }
  
  let currentDate = new Date();
  let lastPingDate = new Date(date);
  let sevenDayBeforeDate = new Date(currentDate.setDate(currentDate.getDate() - 7));

  if (lastPingDate >= sevenDayBeforeDate) {
    return true
  } else {
    return false;
  }

}

const prependHttps = (url) => {
  if (!/^https?:\/\//i.test(url)) {
    url = 'https://' + url;
  }
  return url
}

const handleBackButtonAction = (history) => {
  history.goBack();
};

const usePrevious = (value) => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

const subscribeTime = (dateStr) => {
  let time = moment.parseZone(dateStr)
  if (moment().utcOffset(dateStr).diff(time, 'days') == 0 ){
    return time.fromNow()
  } else {
    return time.format("ll")
  }
}

const loadScript = ({ id, src, async = true, onerror, onload }) => {
  let script = document.createElement("script");
  script.id = id;
  script.src = src;
  script.async = async;
  script.onload = onload;
  script.onerror = onerror;
  return script
}

const loadLink = ({ id, href, rel }) => {
  let link = document.createElement("link");
  link.id = id;
  link.href = href;
  link.rel = rel;
  return link
}

const loadUnlayerScript = (props) => {
  const { initUnlayer } = props || {}
  const url = window?.unlayerToolUrls?.media_library
  let jQueryScript, mediaLibraryScript, unlayerScript, jQueryModalScript, jqueryCssLink = null

  unlayerScript = loadScript({
    id: 'unlayer',
    src: initUnlayer ? 'https://editor.unlayer.com/embed.js' : '',
    async: true,
    onload: () => {
      initUnlayer && initUnlayer()
    }
  })

  mediaLibraryScript = loadScript({
    id: 'media-library',
    src: url,
    async: true,
    onload: () => {
      window.onload()
      document.body.appendChild(unlayerScript)
    },
    onerror: () => {
      // load unlayer if theres an error loading media library
      document.body.appendChild(unlayerScript)
    }
  })

  jQueryScript = loadScript({
    id: 'jquery',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.0.0/jquery.min.js',
    async: true,
    onload: () => {
      document.body.appendChild(mediaLibraryScript)
      document.body.appendChild(jQueryModalScript)
      document.body.appendChild(jqueryCssLink)
    }
  })

  jQueryModalScript = loadScript({
    id: 'jqueryModal',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/jquery-modal/0.9.1/jquery.modal.min.js',
    async: true,
    onload: () => {
    }
  })

  jqueryCssLink = loadLink({
    id: 'jqueryModalCss',
    href: 'https://cdnjs.cloudflare.com/ajax/libs/jquery-modal/0.9.1/jquery.modal.min.css',
    rel: 'stylesheet'
  })
  
  document.body.appendChild(jQueryScript)

  return () => {
    mediaLibraryScript && document.body.removeChild(mediaLibraryScript)
    jQueryScript && document.body.removeChild(jQueryScript)
    jQueryModalScript && document.body.removeChild(jQueryModalScript)
    jqueryCssLink && document.body.removeChild(jqueryCssLink)
    unlayerScript && document.body.removeChild(unlayerScript)
  }
}

async function isDomainSupperessed(domain) {
  const { data } = await client.query({ query: IS_DOMAIN_SUPPRESSED, variables: { domain } });
  return data?.isDomainSuppressed || false;
}

async function isValidEmail(email) {
  try {
    let validEmail = true
    const [name, domain] = email.split('@')
    if(name.includes('guest_') && domain.includes('mailmunch')) validEmail = false; 
    else if (domain) validEmail = !(await isDomainSupperessed(domain))
      
    return validEmail;
  } catch (error) {
    return true;
  }
}

function isUnsupportedEmail(email) {
  let domains = ["mailmunch.", "mail.mmdlv.", "mail1.mmdlv", "mail2.mmdlv", "yahoo.", "rocketmail.com", "ymail.com", "hotmail.", "aol.com"];
  let isUnsupportedEmail = false;
  domains.forEach(function(domain) {
    if (email.indexOf("@" + domain) > -1) isUnsupportedEmail = true;
  });
  return isUnsupportedEmail;
}

function getDateDifference(date, currentDate = new Date()) {
  if(date){
    const Difference_In_Time = new Date(date).getTime() -  new Date(currentDate).getTime();
    return (Difference_In_Time / (1000 * 3600 * 24))?.toFixed();
  }
}

function debounce(func, wait, immediate) {
  var timeout;

  return function executedFunction() {
    var context = this;
    var args = arguments;
	    
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;
	
    clearTimeout(timeout);

    timeout = setTimeout(later, wait);
	
    if (callNow) func.apply(context, args);
  };
};

const getMultiples = (f, t) => 
    [...(Array(Math.floor(t / f)))]
    .map((_, i) => f * (i + 1));
    
function isWhitelistSiteId(siteId) {
  return [415508, 995779, 1007218].includes(parseInt(siteId))
}

function hiphenValidator(value) {
  return value.replace(" ", '-').replace(/[^a-z-A-Z0-9]/g, '')
}

function storageAvailable(type) {
  let storage;
  try {
    storage = window[type];
    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}

function getStorageItem(key) {
  if (storageAvailable("localStorage")) {
    return localStorage.getItem(key);
  }
}

function setStorageItem(key, value) {
  if(storageAvailable("localStorage")){
    return localStorage.setItem(key, value);
  }
}

function removeStorageItem(key) {
  if(storageAvailable("localStorage")){
    return localStorage.removeItem(key);
  }
}

const storageKeys = {
  userToken: ['utk', 'user:token'],
  userTokenExpiry: ['utk:expiry', 'user:token:expiry'],
  userImpersonated: ['im', 'user_impersonated'],
  agentToken: ['atk', 'agent_token'],
};

function fetchFromStorage(key) {
  const keys = storageKeys[key];
  for (const k of keys) {
    const value = getStorageItem(k);
    if (value) return value;
  }
  return null;
}

function removeFromStorage(key) {
  const keys = storageKeys[key];
  for (const k of keys) {
    const result = removeStorageItem(k);
    if (result) return result;
  }
  return null;
}

function setUserToken (token, rememberMe = false) {
  const expiryDate = new Date()
  expiryDate.setDate(expiryDate.getDate() + (rememberMe ? 30 : 1))
  setStorageItem('utk', token);
  setStorageItem('utk:expiry', expiryDate.toISOString());
}

function collaborationCSS (type) {
  const isLandingPage = type === 'landing_page'
  return `
      .blockbuilder-layer-selector-row > .blockbuilder-layer-collaboration-popover-container {
        right: 380px !important;
      }
      .blockbuilder-layer-collaboration-popover-container {
        right: ${isLandingPage ? '205px' : '100px'} !important;
      }

      @media only screen and (max-width: 1300px) {
        .blockbuilder-layer-collaboration-popover-container {
          right: ${isLandingPage ? '355px' : '270px'} !important;
        }
      }
      @media only screen and (min-width: 1301px) and (max-width: 1400px) {
        .blockbuilder-layer-collaboration-popover-container {
          right: ${isLandingPage ? '340px' : '225px'} !important;
        }
      }
      @media only screen and (min-width: 1401px) and (max-width: 1500px) {
        .blockbuilder-layer-collaboration-popover-container {
          right: ${isLandingPage ? '300px' : '150px'} !important;
        }
      }
    `
}


function getStringValue(prop) {
  if (['string', 'number'].includes(typeof prop)) {
    return prop;
  }

  if (React.isValidElement(prop) && prop.type === FormattedMessage) {
    const { defaultMessage, id } = prop.props;
    return defaultMessage || id || '';
  }

  return '';
}

const toCapitalize = (str, space = true) => {
	if (!str) return ''
	if (typeof str !== 'string') return ''

	const isUpperCase = str === str?.toUpperCase()
	const isCapitalized = str === str.charAt(0).toUpperCase() + str.slice(1)

	if (isUpperCase || isCapitalized) {
		return str
	}

	return str
		.replace(/[-_](\w)/g, (_, c) => c.toUpperCase())
		.replace(/\B[A-Z]/g, (m) => (space ? ' ' + m : '' + m))
		.trim()
}

const toPascalCase = (str) => {
	if (!str) return ''
	if (typeof str !== 'string') return ''

	const isUpperCase = str === str.toUpperCase()
	const isCapitalized =
		str[0] === str[0].toUpperCase() &&
		str.slice(1) === str.slice(1).toLowerCase()

	if (isUpperCase || isCapitalized) {
		return str
	}

	return str
		.replace(/[-_](\w)/g, (_, c) => c.toUpperCase())
		.replace(/\B[A-Z]/g, (m) => ' ' + m)
		.replace(/^./, (c) => c.toUpperCase())
		.trim()
}

const journeyTriggers = {
  order_created: 'Order Created',
  checkout_created: 'Checkout Created'
}

const getTriggerName = (partnerTrigger) => 
  window.partnerTriggers?.[partnerTrigger] ?? journeyTriggers[partnerTrigger] ?? null;

const sendBulkTestEmails = async (emails, siteId, emailId, populateEmail) => {
  try {
    const response = await fetch(`//${window.appDomain}/sites/${siteId}/emails/${emailId}/send_test_email?token=${window.token}`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 
        emails: emails,
        ...populateEmail && { populate_email: populateEmail }
      })
    });

    const res = await response.json();

    if (response.status !== 200) {
      throw new Error(res.error);
    }

    return {
      success: true,
      emails
    }
  } catch (error) {
    return {
      success: false,
      error: error.message
    }
  }
}

const handleSendEmail = async ({ selectedEmail, siteId, emailId, onSuccess, setLoading, populateEmail, shouldPopulate, setContactError }) => {
  setLoading && setLoading(true)
  try {
    const response = await sendBulkTestEmails([selectedEmail?.label], siteId, emailId, (shouldPopulate && populateEmail))
    
    if (!response.success) {
      throw new Error(response.error)
    }

    ToastNotification({
      id: randomId(),
      icon: 'check',
      color: 'success',
      body: (
        <FormattedMessage
          id='email_builder.send_test_email_modal.toast.success'
          values={{ email: selectedEmail?.label }}
          defaultMessage='Test email has been sent to {email}'
        />
      ),
    })
    onSuccess && onSuccess()
  } catch (error) {
    if (error.message.includes('Contact not found')) {
      setContactError && setContactError("This contact does not exist.")
    } else {
      ToastNotification({
        id: randomId(),
        icon: 'alert',
        color: 'danger',
        body: (
          <FormattedMessage
            id='email_builder.send_test_email_modal.toast.error'
            values={{
              email: selectedEmail?.label,
            }}
            defaultMessage='Test email could not be sent to {email}. Please try again.'
          />
        ),
      })
    }
  }
  setLoading && setLoading(false)
}

// List of characters to be removed
const charactersToRemove = ['/', '::'];

const formatDataAttribute = (value) => {
  // Convert value to string based on its type
  let stringValue;
  if (value === null || value === undefined) {
    stringValue = 'default';
  } else if (typeof value === 'string') {
    stringValue = value;
  } else if (React.isValidElement(value)) {
    stringValue = value.props.defaultMessage || value.props.children || '';
  } else {
    stringValue = String(value);
  }

  
  // Remove special characters
  const specialCharsRegex = new RegExp(`[${charactersToRemove.join('')}]`, 'g');
  stringValue = stringValue.replace(specialCharsRegex, ' ');
  
  // Remove any other special characters
  stringValue = stringValue.replace(/[^a-zA-Z0-9-]/g, ' ');

  // Replace spaces with hyphens
  stringValue = stringValue.replace(/\s+/g, '-');

  return stringValue.toLowerCase();
}

function formatToTitleCase(input) {
  return input
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}


export { 
  sendBulkTestEmails,
  handleSendEmail,
  toPascalCase,
  toCapitalize,
  getStringValue,
  createPath, 
  deepEqual, 
  resolve, 
  resolveUpdate, 
  getFormFields,
  getObject, 
  generateFontSizes, 
  hasPlatformFeature, 
  useDebounce, 
  handleBackButtonAction,
  copyStringToClipboard, 
  SHOPIFY_PAGE_TYPES, 
  getCampaignDate, 
  isValidURL, 
  randomId, 
  kFormatter, 
  getStartDate, 
  getShortDate, 
  getSiteStatus,
  prependHttps,
  usePrevious,
  subscribeTime,
  loadScript,
  loadLink,
  loadUnlayerScript,
  validateRules,
  isUnsupportedEmail,
  getDateDifference,
  debounce,
  getMultiples,
  isValidEmail,
  isWhitelistSiteId,
  hiphenValidator,
  getStorageItem,
  storageAvailable,
  setStorageItem,
  removeStorageItem,
  setUserToken,
  collaborationCSS,
  getTriggerName,
  formatDataAttribute,
  formatToTitleCase,
  fetchFromStorage,
  removeFromStorage
}
